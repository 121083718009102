import { AppContext } from "../store";
import { Show, createEffect } from "solid-js";
import { getAppFlavor } from "../apis";
import { state, log, theme, Atom } from ":mods";
import { AppViewProps } from "../model";

export function AppFlavor(props: AppViewProps) {
  const $flavor = state.createAsync(getAppFlavor);
  $flavor.on((event, { value }) => {
    const active_vars = value.theme?.user_index || value.theme?.admin_index || 0;
    theme.set(value.theme?.schemes[active_vars] as any);

    if (props.onLoaded) {
      props.onLoaded(value);
    }
  });

  return (
    <Show
      when={!$flavor.state.loading}
      fallback={
        <Atom.Loader.Platform
          class="relative flex$col extend$ fight$"
          title={props.title ?? "Hackyard"}
          msg={props.message ?? "Loading platform..."}
        />
      }
    >
      <AppContext.Provider value={$flavor.value}>{props.children}</AppContext.Provider>
    </Show>
  );
}
